
import BtnMain from '@/components/parts/button/BtnMain.vue'
import LogoMainSVG from '@/components/svg/LogoMainSVG.vue'
export default {
  name: 'ErrorMessage',

  components: {
    BtnMain,
    LogoMainSVG,
  },

  props: {
    error: {
      type: Object,
      default: () => ({
        statusCode: 404,
      }),
    },
  },
}
